<template>
  <div class="container">

    <div class="formed-load-calculation mt-4 mb-4">
      <h4 class="text-center mb-4">Сформированный расчет нагрузки</h4>

      <div class="row mb-4">
        <div class="col-md-12">
          <button @click="$router.push('load-calculation')" class="btn btn-primary">Расчет нагрузки</button>


        </div>
      </div>

      <DataTable :value="formedLoadCalculation_form.selectionSupervisors" :paginator="true" :rows="10"
                 paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                 :rowsPerPageOptions="[10,20,50]"
                 filterDisplay="menu"
                 v-model:filters="filters"
                 currentPageReportTemplate="Показано {last} из {totalRecords}"
                 stripedRows responsiveLayout="scroll">
        <Column header="Руководитель">
          <template #body="{data}">
            {{data.lastname}} {{data.firstname}}
          </template>
        </Column>

        <Column header="Название ОП">
          <template #body="{data}">

            {{ data.education_speciality_code }} {{data.education_speciality_name }}


          </template>
        </Column>
        <Column field="native_name" header="Язык ОП"></Column>
        <Column field="study_course" header="Курс"></Column>
        <Column field="semester" header="Семестр"></Column>
        <Column field="study_level_name" header="Уровень обучения">

          <template #filter="{filterModel}">
            <Dropdown v-model="filterModel.value" :options="studyLevels" placeholder="Любой"
                      class="p-column-filter" :showClear="true">
              <template #value="slotProps">
                <span v-if="slotProps.value">{{slotProps.value}}</span>
                <span v-else>{{slotProps.placeholder}}</span>
              </template>
              <template #option="slotProps">
                <span>{{slotProps.option}}</span>
              </template>
            </Dropdown>
          </template>

        </Column>

        <Column field="student_counts" header="Количество студентов"></Column>

        <Column field="selection_supervisors_hours" header="Часы"></Column>





      </DataTable>




    </div>
  </div>
</template>


<script>
import {FilterMatchMode, FilterOperator} from 'primevue/api';
import {mapGetters, mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "FormedLoadCalculation",
  components: {},
  data() {
    return {

      studyLevelId: 0,
      educationProgramId: 0,
      filters: {

        'study_level_name': {value: null, matchMode: FilterMatchMode.EQUALS},



      },
      studyLevels: ['Бакалавриат', 'Магистратура (1 годичное,профильное)', 'Магистратура (1,5 годичное, профильное)',
        'Магистратура (2 годичное, научно-педагогическое)', 'Докторантура (научно-педагогическое направление)', 'MBA', 'EMBA'],
    }
  },
  computed: {
    ...mapState('formedLoadCalculation', ['formedLoadCalculation_form']),

  },

  methods: {
    ...mapActions('formedLoadCalculation', ['GET_SELECTION_SUPERVISORS']),
    ...mapMutations('formedLoadCalculation', ['']),

  },

  async mounted() {
    await this.GET_SELECTION_SUPERVISORS()

  }
}
</script>

<style scoped>
</style>